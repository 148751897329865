<template>
  <div class="recruit">
    <Header></Header>
    <Basebanner :banner="'recruit'"></Basebanner>
    <div class="top_msg">
      <div class="title_content">
        <h2>欢迎加入我们，与您共创事业</h2>
        <span>集设计、生产于一体的黄金、K金、铂金首饰生产企业，所有产品完全独立设计，自主开发。</span>
      </div>
      <div class="icon_content">
        <div class="icon_item_father" v-for="(item,index) in iconList">
          <img :src="item.img"/>
          <p class="item_title">{{ item.title }}</p>
          <p class="item_contxt">{{ item.contxt }}</p>
        </div>
      </div>
    </div>
    <div class="main_recruit">
      <div class="main_content">
        <p class="title">招聘职位</p>
        <table class="el-table__body" style="width: 100%; text-align: center; border-collapse: collapse;">
          <thead>
          <!--        <th v-for="(item,index) in recruitList">{{item.title}}</th>-->
          <th>岗位名称</th>
          <th>工作地点</th>
          <th>招聘人数</th>
          <th>查看详情</th>
          </thead>
          <tbody>
          <tr v-for="(item,index) in recruitList" class="row-tr">
            <td>{{ item.name }}</td>
            <td>{{ item.address }}</td>
            <td>{{ item.num }}</td>
            <td class="check" @click="toCheck(item)">点击查看</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";
import Basebanner from "@/components/BaseBanner.vue";

export default {
  name: "recruitment",
  components: {Basebanner, Footer, Header},
  data() {
    return {
      iconList: [
        {
          img: require('../static/recruit/icon1.png'),
          title: '诚信为本',
          contxt: '无论在何时都应将言必信、行必正作为基本信条，自觉维护企业在社会形象。'
        },
        {
          img: require('../static/recruit/icon2.png'),
          title: '团队精神',
          contxt: '鼓励员工尽心尽力为团体合作的长远利益而努力，从而增进相互之间的信任。'
        },
        {
          img: require('../static/recruit/icon3.png'),
          title: '认同、参与、共享',
          contxt: '倡导创造价值、培植使命感、责任感、荣誉感和成就感，培养高素质人才。'
        },
      ],
      recruitList: [
        {
          name: '药店店长',
          address: '湖南省雨花区',
          num: '10',
          info: {
            zhize: `<p>1、大专及以上学历，市场营销等相关专业，有能力不限；</p>
                    <p>2、年连锁药店区域运营经验；</p>
                    <p>3、反应敏捷、表达能力强，具有较强的沟通能力及交际技巧，具有亲和力；</p>
                    <p>4、具备一定的市场分析及判断能力，良好的客户服务意识；</p>
                    <p>5、有责任心，能承受较大的工作压力；</p>
                    <p>6、公司化运营，高薪诚聘，适合的可以直接过来公司面试，工作地点就近安排。</p>
                    `,
            yaoqiu: `<p>1、负责药店运营；</p>
                     <p>2、根据市场营销计划，完成销售指标；</p>
                     <p>3、开拓其他区域新市场,发展新客户,增加药品销售范围；</p>
                     <p>4、负责运营区域销售活动的策划和执行；</p>
                     <p>5、负责辖区市场商品品类，价格信息的收集及竞争对手的分析；</p>
                     <p>6、管理维护客户关系以及客户间的长期战略合作计划；</p>
                     <p>7、能积极配合及完成上级下达的各项任务指标。</p>
                        `,
          }
        },
        {
          name: '电商运营',
          address: '湖南省雨花区',
          num: '3',
          info: {
            zhize: `<p>1、熟悉平台营销规则，了解用户的购物习惯和心理，并具有良好的在线销售能力，</p>
                    <p>2、能对店面布局和版面的改善提出持续的跟进意见，，</p>
                    <p>3、敢于挑战自我，热爱网络销售及推广工作，对电子商务发展有清晰的认识，创新意识较强，</p>
                    <p>4、较好的人际沟通能力、组织协调能力和应变能力，具有强烈的责任心和事业心；</p>`,
            yaoqiu: `<p>1、负责网店整体规划、营销、推广、客户关系管理等系统经营性工作，</p>
                     <p>2、负责商品标题、广告语详情优化，以获得更多自然流量，提高成交率，</p>
                     <p>3、具备创新能力，富有激情和创意，不断寻找新思路、新方法，提高公司电子商务平台的知名度，</p>
                     <p>4、负责网店日常改版策划、上架、推广、销售、售后服务等经营与管理工作，每日监控的数据包括但不限于营销数据、交易数据、商品管理、客户管理，</p>`,
          }
        },
        {
          name: '销售经理',
          address: '湖南省雨花区',
          num: '10',
          info: {
            zhize: `<p>1、专科及以上学历，有医药、耗材、医疗设备销售经验者优先；</p>
                    <p>2、具有较强的独立工作能力和社交技巧，较好的沟通能力、协调能力和团队合作能力；</p>
                    <p>3、身体健康，热爱销售工作，能适应湖南省范围内的短期出差、能接受因工作需要的加班、应酬；</p>
                    <p>4、熟练使用基础办公软件、具备较好的理解和表达能力，家庭农村出身者优先考虑,能力优秀者可适当放宽要求。</p>`,
            yaoqiu: `<p>1、通过学术会议推广公司代理产品；</p>
                     <p>2、在辖区内医院进行公司产品的推广销售和相关信息收集，协调好各层关系，完成销售任务；</p>
                     <p>3、根据需要拜访医护人员，向客户推广产品，不断提高产品市场份额；</p>
                     <p>4、开拓潜在的医院渠道客户，并对既有的客户进行维护；</p>
                     <p>5、充分了解市场状态，及时向公司相关负责人反映竞争对手的情况及市场动态、提出合理化建议；</p>
                     <p>6、制定并实施辖区医院的推销计划，组织医院内各种推广活动；</p>
                     <p>7、树立公司的良好形象， 对公司商业秘密做到保密；</p>
                     <p>8、组建自己的销售团队，并进行培养和管理。</p>
                        `,
          }
        },
        {
          name: '高薪诚聘网店运营美工',
          address: '湖南省雨花区',
          num: '3',
          info: {
            zhize: `<p>1、个人熟悉淘宝基本规则，了解淘宝营销工具及各类推广工具的使用；</p>
                    <p>2、熟悉网店的运营环境等。</p>
                    <p>3、拥有1年以上实操运营经验和团队管理经验。</p>
                    `,
            yaoqiu: `<p>1、负责网店整体规划、营销、推广、客户关系管理等系统经营性工作；</p>
                     <p>2、负责网店日常改版策划、上架、推广、销售、售后服务等经营与管理工作；</p>
                     <p>3、负责网店日常维护，保证网店正常运作，优化店铺和商品排名；</p>
                     <p>4、每日监控的数据：营销数据、交易数据、商品管理、顾客管理 。</p>
                     <p>5、负责执行与配合公司相关营销活动，策划店铺促销活动方案；</p>
                     <p>6、美工，会PS,处理图片，有独特的想法</p>
                        `,
          }
        },
        {
          name: '岳泰兴医疗高薪诚聘财务会计',
          address: '湖南省雨花区',
          num: '2',
          info: {
            zhize: `<p>1、财务，会计，经济等相关专业大专以上学历，具有会计任职资格；</p>
                    <p>2、具有扎实的会计基础知识和一年以上财会工作经验，并具备一定的英语读写能力；</p>
                    <p>3、熟悉现金管理和银行结算，熟悉用友或其他财务软件的操作；</p>
                    <p>4、具有较强的独立学习和工作的能力，工作踏实，认真细心，积极主动；</p>
                    <p>5、具有良好的职业操守及团队合作精神，较强的沟通、理解和分析能力。</p>
                    `,
            yaoqiu: `<p>1、申请票据，购买发票，准备和报送会计报表，协助办理税务报表的申报；/p>
                     <p>2、现金及银行收付处理，制作记帐凭证，银行对帐，单据审核，开具与保管发票；</p>
                     <p>3、协助财会文件的准备、归档和保管；</p>
                     <p>4、固定资产和低值易耗品的登记和管理；</p>
                     <p>5、负责与银行、税务等部门的对外联络；</p>
                     <p>6、协助主管完成其他日常事务性工作。</p>
                        `,
          }
        },
        {
          name: '医疗器械销售',
          address: '湖南省雨花区',
          num: '20',
          info: {
            zhize: `<p>1、医学、医药类相关专业优先；</p>
                    <p>2、有医疗器材销售经验者优先；</p>
                    <p>3、热爱销售岗位，有清晰的个人认识和职业规划；</p>
                    <p>4、工作积极主动、性格外向、善于沟通、有亲和力，能承受一定的压力</p>
                    <p>5、大专以上学历优先考虑</p>
                    `,
            yaoqiu: `<p>1、完成市场客户覆盖，建立起所辖区域内的详细市场信息，协助上级管理及维护销售渠道；</p>
                     <p>2、负责在责任区域进行销售工作，完成个人销售指标，负责责任区域内的应收账款；</p>
                     <p>3、执行新产品、新市场的开拓计划、提高产品的市场覆盖率；</p>
                     <p>4、了解所辖区域内的主要竞争对手；</p>
                     <p>5、负责产品的价格体系的管控、渠道建设、推广方案的制定与实施；</p>
                     <p>6、充分了解市场状态，及时向上级主管反映竟争对手的情况及市场动态、提出合理化建议；</p>
                     <p>7、树立公司的良好形象， 对公司商业秘密做到保密。</p>
                        `,
          }
        },
      ]
    }
  },
  methods: {
    toCheck(item) {
      this.$router.push({name: 'recruitInfo', query: {info: item}})
    }
  }
}
</script>

<style scoped lang="scss">
.recruit {
  width: 100%;

  .top_msg {
    margin: 0 auto;
    width: 1200px;
    min-height: 500px;
    padding: 20px 0;

    .title_content {
      margin-top: 40px;
      text-align: center;

      h2 {
        font-size: 40px;
        color: rgb(0, 0, 0);
        font-weight: 600;
        font-family: SourceHanSansCN-Regular;
        margin-bottom: 30px;
      }

      span {
        color: rgb(136, 136, 136);
        font-size: 16px;
      }
    }

    .icon_content {
      display: flex;
      justify-content: space-between;

      .icon_item_father {
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 26%;

        img {
          width: 64px;
          height: 64px;
        }

        .item_title {
          font-weight: 600;
          font-size: 22px;
          letter-spacing: 1px;
          margin: 20px 0;
        }

        .item_contxt {
          text-align: center;
          color: #888888;
          font-size: 16px;
          letter-spacing: 1px;
        }
      }
    }
  }

  .main_recruit {
    width: 100%;
    border-top: 1px solid #ccc;
    min-height: 800px;

    .main_content {
      width: 1400px;
      margin: 0 auto;

      .title {
        font-size: 36px;
        font-weight: 600;
        margin: 50px 0;
        letter-spacing: 1px;
        text-align: center;
      }

      table {
        margin-top: 40px;

        th {
          font-weight: 600;
          margin-bottom: 30px;
          font-size: 15px;
          line-height: 50px;
        }

        .row-tr {
          line-height: 80px;

          td {
            border-bottom: 1.5px dashed #ccc;
          }

          .check {
            cursor: pointer;
          }

          .check:hover {
            color: #02b2b5;
          }
        }
      }

      tr:hover {
        background: #e7e7e7;
      }
    }

  }
}
</style>