<template>
  <div :class="['baseBanner',banner]"></div>
</template>

<script>
export default {
  name: 'Basebanner',
  props:{
    banner:{
      type:String,
      default:'about'
    }
  }
}
</script>

<style scoped lang="scss">
.baseBanner {
  height: 300px;
  cursor: default;
  background-position: 50% 50%!important;
  background-repeat: no-repeat!important;
  overflow: hidden;
  display: block;
  outline: none;
  margin: 0px auto;
  position: relative;
  z-index: 1;
  background-size: cover!important;
}
.brand{
  background: url('../assets/img/banner/brand.jpg');
}
.franchise{
  background: url('../assets/img/banner/franchise_banner.jpg');
}
.enterprise{
  background: url('../assets/img/banner/enterprise_banner.jpg');
}
.about{
  background: url('../assets/img/banner/about_banner.jpg');
}
.recruit{
  background: url('../assets/img/banner/recruit.jpg');
}
</style>